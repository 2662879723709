import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JSend } from '../../models/jsend.model';
import { Integration } from '../models/integration.model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  private baseUrl = '/:apiIntegrationsMonitor/';
  private getIntegrationsMonitorUrl = this.baseUrl + '?companies=:companiesId';

  constructor(
    private http: HttpClient
  ) { }

  /** Returns all [[Integrations]] [[Integrations|Integrations]]. */
  public get(companiesId?: [number]): Observable<Integration[]> {
    let url = this.getIntegrationsMonitorUrl;
    if (companiesId) {
      url = url.replace(":companiesId", companiesId.join(','));
    } else {
      url = url.replace(":companiesId", '');
    }

    const stream = this.http.get<JSend<{
      resources: Integration[];
    }>>(url, {}).pipe(map(response => plainToInstance(Integration, response.data.resources)));

    return stream;
  }
}
