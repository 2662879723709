<ag-cellphone-verification
#cellVerification></ag-cellphone-verification>

<div class="container">
  <div class="content-box">
    <div class="row">
      <div class="col-xs-12">
        <h3>{{ 'GLOBAL.USER_PROFILE'|translate }}</h3>

        <tabset>
          <ng-container *ngFor="let section of sections">
            <tab 
            [active]="section.slug === sectionSlug"
            [disabled]="processing"
            (selectTab)="navigateTo(section)"
            heading="{{ section.title|translate }}" id="tab_{{ section.id }}">
              <ng-container *ngTemplateOutlet='section.template'></ng-container>
            </tab>
          </ng-container>
        </tabset>
      </div>
    </div>
  </div>
</div>

<ng-template #infoTemplate>
  <p class="text-muted" [innerHtml]="'USER_PROFILE.PROFILE_HELP'|translate"></p>

  <alert *ngIf="errorMessage"
  type="danger" [dismissible]="true" (onClosed)="errorMessage = undefined">
    <span [innerHtml]="errorMessage|translate"></span>
  </alert>
  <alert *ngIf="successMessage"
  type="success" [dismissible]="true" (onClosed)="successMessage = undefined">
    <span [innerHtml]="successMessage|translate"></span>
  </alert>

  <form *ngIf="sectionSlug === 'info'"
  class="form-updateProfile" (ngSubmit)="updateForm.valid && profileUpdate()" #updateForm="ngForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group" hasError>
          <label for="name" class="control-label">{{ 'GLOBAL.NAME'|translate }}</label>
          <input id="name" type="text" class="form-control input-lg"
          [agAutoFocus]="true"
          name="name"
          maxlength="128"
          [disabled]="processing"
          [(ngModel)]="userTemp.name" required>
          <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
          <error-list></error-list>
        </div>                
      </div>
      <div class="col-md-6">
        <div class="form-group" hasError>
          <label for="lastName" class="control-label">{{ 'SIGNUP.LAST_NAME'|translate }}</label>
          <input id="lastName" type="text" class="form-control input-lg"
          name="lastName"
          maxlength="128"
          [disabled]="processing"
          [(ngModel)]="userTemp.last_name" required>
          <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
          <error-list></error-list>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="email" class="control-label">{{ 'SIGNUP.EMAIL'|translate }}</label>
          <input id="email" type="email" class="form-control input-lg" name="email" email readonly value="{{ user?.email }}">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="language" class="control-label">{{ 'USER_PROFILE.LANGUAGE'|translate }}</label>
          <select id="language" class="form-control input-lg" name="language" required [(ngModel)]="userTemp.language"
          [disabled]="processing">
            <option *ngFor="let lang of languages" [ngValue]="lang.slug">{{ lang.name }}</option>
          </select>
        </div>
      </div>
    </div>

    <button class="btn btn-lg btn-agree btn-block" type="submit"
    [disabled]="processing || updateForm.pristine">{{ 'USER_PROFILE.PROFILE_UPDATE'|translate }}</button>
  </form>

</ng-template>
<ng-template #channelsTemplate>
  <p class="text-muted" [innerHtml]="'USER_PROFILE.CHANNELS.HELP'|translate"></p>

  <div class="form-group">
    <label for="phone" class="control-label">{{ 'USER_PROFILE.PHONE'|translate }}</label>
    <a (click)="cellVerification.addPhone()"
    class="btn-link btn-sm"
    [innerText]="(user?.phone?.phone_number ? 'CELLPHONE_VERIFICATION.MODIFY' : 'CELLPHONE_VERIFICATION.ADD')|translate"></a>
    <input readonly id="phone" type="text" class="form-control input-lg" name="phone"
    [value]="user?.phone?.phone_number ?  ('+' + user.phone.phone_country_code.phone_code + ' (' + user.phone.phone_area_code + ') ' + user.phone.phone_number) : ''">
  </div>

  <ng-container *ngIf="userChannels">
    <h4>{{ 'USER_PROFILE.CHANNELS.TITLE'|translate }}</h4>
    <p class="text-muted" [innerHtml]="'USER_PROFILE.CHANNELS.ENABLE'|translate"></p>

    <table class="table table-condensed">
      <tbody>
        <tr *ngFor="let channel of userChannels; let i = index">
          <td>
            <div class="checkbox flex-center-distributed">
              <div>
                <input type="checkbox" class="ios"
                name="channel_{{ i }}_chk" id="channel_{{ i }}"
                [disabled]="processing"
                [(ngModel)]="channel.status" (ngModelChange)="updateChannels()">
              </div>
              <label for="channel_{{ i }}" class="grow">{{ channel.name }}</label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

</ng-template>
<ng-template #passwordTemplate>
  <p class="text-muted" [innerHtml]="'USER_PROFILE.PASSWORD.HELP'|translate"></p>

  <alert *ngIf="errorMessage"
  type="danger" [dismissible]="true" (onClosed)="errorMessage = undefined">
    <span [innerHtml]="errorMessage|translate"></span>
  </alert>
  <alert *ngIf="successMessage"
  type="success" [dismissible]="true" (onClosed)="successMessage = undefined">
    <span [innerHtml]="successMessage|translate"></span>
  </alert>

  <form *ngIf="sectionSlug === 'password'"
  class="form-password" (ngSubmit)="passwordForm.valid && changePassword()" #passwordForm="ngForm">
    <div class="form-group" hasError *ngIf="user.has_password">
      <label for="current" class="control-label">{{ 'USER_PROFILE.PASSWORD.OLD'|translate }}</label>
      <input id="current" type="password" class="form-control input-lg"
      [agAutoFocus]="true"
      [disabled]="processing"
      name="password" required minlength="6"
      [(ngModel)]="password.password">
      <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
      <error-list></error-list>
    </div>
    <fieldset ngModelGroup="passwordGroup" [matches]="['newPassword', 'newConfirmation']" hasError>
      <div class="form-group" hasError>
        <label for="newPassword" class="control-label">{{ 'USER_PROFILE.PASSWORD.NEW'|translate }}</label>
        <input id="newPassword" type="password" class="form-control input-lg"
        [disabled]="processing"
        name="newPassword" required
        [(ngModel)]="password.newPassword"
        #passwordInput="ngModel"
        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$">
        <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
        <password-strength-meter [password]="passwordInput.valid ? password.newPassword : ''"></password-strength-meter>
        <span class="help-block small no-margin" [innerHtml]="'SIGNUP.PASSWORD_HELP'|translate"></span>
        <error-list></error-list>
      </div>
      <div class="form-group" hasError>
        <label for="newConfirmation" class="control-label">{{ 'SIGNUP.PASSWORD_CONFIRM'|translate }}</label>
        <input id="newConfirmation" type="password" class="form-control input-lg"
        [disabled]="processing"
        name="newConfirmation" required ngModel>
        <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
        <error-list></error-list>
      </div>
      <error-list></error-list>
    </fieldset>

    <button class="btn btn-lg btn-agree btn-block" type="submit"
    [disabled]="processing || passwordForm.pristine">{{ 'USER_PROFILE.PASSWORD.UPDATE'|translate }}</button>
  </form>
</ng-template>
