import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LoginService } from '../../auth/services/login.service';
import { Market } from '../../company/modules/commercial/models/market.model';
import { Order } from '../../company/modules/commercial/models/order.model';
import { OrderService } from '../../company/modules/commercial/services/order.service';
import { qualityToString } from '../../company/pipes/quality.pipe';
import { GroupBy } from '../../models/group-by.model';
import { Product } from '../../models/product.model';
import { MarketService } from '../../services/market.service';
import { FilterSetComponent } from '../../ui/components/filter-set/filter-set.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  @ViewChild('filterSet', { static: true }) public readonly selectedFilters: FilterSetComponent;

  public marketId: number;
  private subscriptions: Subscription[] = [];

  /** The language currently used. */
  public currentLang: string;
  public environment = environment;
  public hasOrdersWithGeolocation: boolean = false;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public market: Market;
  public ordersByProduct: GroupBy<Product, Order>[];
  public totalTons: number;
  public viewMode: string;

  public filters: Array<any>;

  constructor(
    private marketService: MarketService,
    private translateService: TranslateService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService
  ) {
    this.marketId = this.route.snapshot.data.marketId || 5; // Default market ID
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      if (user && user.language) {
        this.translateService.use(user.language);
      } else {
        this.translateService.use(this.route.snapshot.data.language || 'es');
      }

      this.getOrders();
    }));
  }

  private getOrders(): void {
    let workingOrders = this.selectedFilters.getFilters().pipe(
      switchMap(filters => {
        this.loading = true;

        this.filters = filters;

        return this.orderService.watchWorkingOrdersByMarket(this.marketId, this.filters);
      })
    );
    let marketData = this.marketService.get(this.marketId);

    this.subscriptions.push(combineLatest([
      workingOrders,
      marketData
    ]).pipe(
      map(([ordersByProduct, marketData]) => {
        return {
          ordersByProduct,
          marketData
        };
      })
    ).subscribe(values => {
      this.market = values.marketData;
      this.viewMode = this.market.configuration.order.allow_media ? 'gallery' : 'table';
      this.parseOrdersResponse(values.ordersByProduct);
    })
    );
  }

  private parseOrdersResponse(ordersByProduct: GroupBy<Product, Order>[]): void {
    this.ordersByProduct = ordersByProduct;

    // TODO: evitar campos calculados de esta manera, agregar getter o pipe
    this.ordersByProduct.forEach(group => {
      group.values.forEach(order => {
        order.quality_string = qualityToString(order.product_detail.quality, group.key);
        if (order.business_detail.delivery.geolocations) {
          this.hasOrdersWithGeolocation = true;
        }
      });
    });

    this.sumTotals();
    this.loading = false;
  }

  private sumTotals(): void {
    this.totalTons = this.ordersByProduct
      .map(group => group.values)
      .reduce((orders1, orders2) => orders1.concat(orders2), [])
      .map(order => order.business_detail.quantity.value || 0)
      .reduce((v1, v2) => v1 + v2, 0);
  }

  public gotoLogin(): void {
    this.router.navigateByUrl('/login');
  }

  // TODO: This should be a pipe
  public countryAndPort(order: Order): string {
    return order.product.attributes.country.name + ' / ' + order.business_detail.port.name;
  }

  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
