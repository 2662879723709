import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ComponentCommService } from '../../../services/component-comm.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { FlashService } from '../../../ui/services/flash.service';
import { OAuthParams } from '../../models/oauth.model';
import { Token } from '../../models/token.model';
import { LoginService } from '../../services/login.service';

declare let google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public availableLanguages: {
    name: string;
    slug: string;
  }[];
  public user = {
    email: null,
    password: null
  };

  public loginError: boolean;
  public sending: boolean;

  private token: Token;
  private reverificationUser: boolean;
  private subscriptions: Subscription[] = [];
  public clientGoogleAuth;

  constructor(
    private router: Router,
    private flashService: FlashService,
    private loginService: LoginService,
    private componentComm: ComponentCommService,
    private activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.availableLanguages = environment.languages;
    this.subscriptions.push(this.loginService.getCurrentToken().subscribe(token => {
      this.token = token;
    }));
  }

  ngOnInit(): void {
    if (this.token) {
      // Already logged in.
      this.router.navigate(['companies'], { queryParams: { referral: 'login' } });
    }

    this.componentComm.emit({ name: 'app-title', title: undefined });

    const client_id: OAuthParams['client_id'] = this.activatedRoute.snapshot.queryParams['client_id'];
    const callback_uri: OAuthParams['callback_uri'] = this.activatedRoute.snapshot.queryParams['callback_uri'];

    if (client_id && callback_uri) {
      this.loginService.setRedirectUrl(`/oauth?client_id=${client_id}&callback_uri=${callback_uri}`)
    };

    this.reverificationUser = this.activatedRoute.snapshot.queryParams['reverification'];
    if (this.reverificationUser) {
      this.flashService.report('verification_pending');
    }
    this.initTokenClientForGoogleAuth();
  }

  public login(): void {
    this.loginError = false;

    this.sending = true;
    const dataLogin = {
      username: this.user.email,
      password: this.user.password,
      ...environment.authClientGrant
    };

    this.subscriptions.push(this.loginService.login(dataLogin).subscribe({
      next: login => {
        if (login && login.user) {
          this.loginService.checkMailVerified();
          let backToUrl = this.loginService.getRedirectUrl();
          if (backToUrl === '/login') backToUrl = undefined;
          let defaultUrl = 'companies?referral=login';

          if (!backToUrl &&
            login.user.accounts &&
            login.user.accounts.length > 0) {
            // New user don't have accounts
            const noAdminAccounts = login.user.accounts.filter(account => account.role_id < 10);

            if (noAdminAccounts.length === 1 &&
              noAdminAccounts[0].company &&
              noAdminAccounts[0].company.activation_level_id) {
              defaultUrl = '/company/' + noAdminAccounts[0].company.id + '/home';
            }
          }

          // this.sending = false;
          this.loginService.clearRedirectUrl();
          this.router.navigateByUrl(backToUrl ? backToUrl : defaultUrl);
        }
      },
      error: error => {
        this.loginError = true;
        this.sending = false;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    }));
  }

  private initTokenClientForGoogleAuth(): void {
    if (!google) return;

    let accessToken;
    this.clientGoogleAuth = google.accounts.oauth2.initTokenClient({
      client_id: environment.google.apiClientID,
      fetch_basic_profile: true,
      scope: "profile email",
      callback: (tokenResponse) => {
        accessToken = tokenResponse.access_token;
        this.loginService.getGoogleAccountUserInfo(accessToken).subscribe(user => {
          this.sending = true;

          const userProfile = {
            'google_id': user.id,
            'full_name': user.name,
            'name': user.given_name,
            'last_name': user.family_name,
            'image_url': user.picture,
            'email': user.email,
            'access_token': accessToken,
          };

          this.successGoogleSignIn(userProfile);
        });
      }
    });
  }

  public siginWithGoogle(): void {
    this.clientGoogleAuth.requestAccessToken();
  }

  private successGoogleSignIn(userProfile: any): void {
    const dataLogin = {
      email: userProfile.email,
      google_access_token: userProfile.access_token
    };

    this.subscriptions.push(this.loginService.login(dataLogin).subscribe(login => {
      this.loginService.checkMailVerified();
      this.sending = false;
      this.router.navigate(['/companies']);
    }));
  }

  public updateLanguage(slug: string): void {
    this.translateService.use(slug);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
