<div class="container container-xs">
  <div class="content-box">
    <form class="form-signin" (ngSubmit)="form.valid && submit()" #form="ngForm">
      <h3>{{ 'PASSWORD_RESET.HEADING'|translate }}</h3>
      <span class="help-block small">{{ 'PASSWORD_RESET.HELP_BLOCK'|translate }}</span>

      <alert type="danger" [dismissible]="true" *ngIf="verification_email">
        <b>{{ 'PASSWORD_RESET.EMAIL_VERIFICATION_ERROR_0'|translate }}</b>
        {{ 'PASSWORD_RESET.EMAIL_VERIFICATION_ERROR_1'|translate }}
        <a [routerLink]="['/login']" [queryParams]="{ reverification: true }" (click)="resend(user_email)">
          {{ 'PASSWORD_RESET.EMAIL_VERIFICATION_ERROR_2'|translate }}
        </a>
        {{ 'PASSWORD_RESET.EMAIL_VERIFICATION_ERROR_3'|translate }}
      </alert>

      <alert type="danger" [dismissible]="true" *ngIf="generic_error">
        {{ 'SIGNUP.UNKNOWN_ERROR'|translate }}
      </alert>

      <div class="form-group" hasError>
        <label for="inputEmail" class="sr-only">{{ 'PASSWORD_RESET.EMAIL'|translate }}</label>
        <input id="inputEmail" type="email" class="form-control input-lg"
        placeholder="{{ 'PASSWORD_RESET.EMAIL'|translate }}"
        autofocus
        name="email"
        [(ngModel)]="user_email"
        (ngModelChange)="emailChange()"
        [disabled]="sending"
        required email #email="ngModel">
        <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
        <error-list></error-list>
      </div>

      <button class="btn btn-lg btn-agree btn-block" type="submit" [disabled]="sending">
        <span>{{ 'GLOBAL.SEND'|translate }}</span>
      </button>
    </form>
  </div>

  <footer>
    <p class="text-center text-muted small">
      <a class="agree-color" routerLink="/login">{{ 'GLOBAL.HAVE_ACCOUNT_ALREADY'|translate }}</a>
      <span class="widther">&bull;</span>
      <a class="agree-color" routerLink="/terms">{{ 'GLOBAL.TERMS_AND_CONDITIONS'|translate }}</a>
      <span class="widther">&bull;</span>
      <a class="agree-color" routerLink="/privacy">{{ 'GLOBAL.PRIVACY_POLICY'|translate }}</a>
      <span class="widther">&bull;</span>
      <a class="agree-color" href="https://www.agree.ag/cookies/index.html">Cookies</a>
    </p>
  </footer>
</div>
