<div class="container container-xs">
  <div class="content-box">
    <form class="form-signin" (ngSubmit)="validation === 'succeeded' && form.valid && submit()" #form="ngForm">
      <h3>{{ 'UPDATE_PASSWORD.TITLE'|translate }}</h3>

      <p class="help-block">{{ 'UPDATE_PASSWORD.HELP'|translate }}</p>

      <fieldset ngModelGroup="passwordGroup" [matches]="['password', 'password_confirmation']" hasError>
        <div class="form-group" hasError>
          <label for="inputPassword" class="sr-only">{{ 'UPDATE_PASSWORD.LABEL_NEW'|translate }}</label>
          <input id="inputPassword" type="password" class="form-control input-lg" placeholder="{{ 'UPDATE_PASSWORD.PLACEHOLDER_NEW'|translate }}" name="password" [(ngModel)]="send_data.password" required [disabled]="validation !== 'succeeded'"
          #password="ngModel"
          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$">
          <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
          <password-strength-meter [password]="password.valid ? send_data.password : ''"></password-strength-meter>
          <span class="help-block small no-margin" [innerHtml]="'SIGNUP.PASSWORD_HELP'|translate"></span>
          <error-list></error-list>
        </div>

        <div class="form-group" hasError>
          <label for="inputPasswordRepeat" class="sr-only">{{ 'UPDATE_PASSWORD.LABEL_CONFIRM'|translate }}</label>
          <input id="inputPasswordRepeat" type="password" class="form-control input-lg" placeholder="{{ 'UPDATE_PASSWORD.PLACEHOLDER_CONFIRM'|translate }}" name="password_confirmation" [(ngModel)]="send_data.password_confirmation" required [disabled]="validation !== 'succeeded'">
          <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
          <error-list></error-list>
        </div>
        <error-list></error-list>
      </fieldset>

      <alert type="warning" [dismissible]="true" *ngIf="validation === 'failed' || form.invalid && form.errors?.remote">
        <span class="glyphicon glyphicon-warning-sign" aria-hidden="true"></span>
        {{ 'UPDATE_PASSWORD.EXPIRED_TOKEN'|translate }}
      </alert>

      <button class="btn btn-lg btn-agree btn-block" type="submit" [disabled]="validation !== 'succeeded' || sending">{{ 'UPDATE_PASSWORD.UPDATE'|translate }}</button>
      <button type="button" routerLink="/login" class="btn btn-link btn-block">{{ 'GLOBAL.CANCEL'|translate }}</button>
    </form>
  </div>
</div>

