<div class="container">
  <div class="content-box">
    <div class="loading" *ngIf="!types; else selector">
      <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
    </div>
  </div>
</div>

<ng-template #selector>
  <div class="row">
    <div class="col-xs-12">
      <h3>{{ 'GLOBAL.ADD_COMPANY'|translate }}</h3>
      <p>{{ 'SELECT_MARKET.HEADING'|translate }}</p>
    </div>
  </div>
  
  <div class="mrk-types" *ngIf="!marketType && grouped">
    <!-- Market type cards -->
    <ng-container *ngFor="let market_type of types; let even = even">
      <div class="type">
        <div class="type-c" (click)="setType(market_type)">
          <img src="{{ market_type.logo }}">
          <h1>{{ market_type.name }}</h1>
          <p>{{ market_type.description }}</p>
          <p class="small text-muted">({{ market_type.subdescription }})</p>
        </div>
      </div>
      <div class="split" *ngIf="even"></div>
    </ng-container>
  </div>

  <div class="row stretch-items" *ngIf="marketType || !grouped">
    <!-- Market cards -->
    <ng-container *ngFor="let market_group of marketsByType">
      <ng-container *ngIf="!grouped || (marketType?.id === market_group.key.id)">
        <ng-container *ngFor="let market of market_group.values">
          <ng-container *ngTemplateOutlet="marketCard; context: { market: market, group: market_group.key }"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #marketCard let-market="market" let-group="group">
  <div class="col-xs-12 col-sm-4 col-md-3">
    <div class="card" [routerLink]="['/register-company', 'market', market.id]">
      <div class="header">
        <div class="logo-circle image shadow" placement="top">
          <!-- Replace with market immage lacted at /assets/img/mkt/ -->
          <div class="logo" [style.backgroundImage]="market.logo|trustStyleUrl"></div>
        </div>
      </div>
      <div class="body">
        <h3 title="{{ market.name }}">{{ market.name }}</h3>
        <div *ngIf="!grouped"
        class="micro text-center text-muted">{{ group.name }}</div>
        <p class="small text-center">{{ market.description }}</p>
      </div>
    </div>
  </div>
</ng-template>