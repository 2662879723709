import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '../auth/services/admin-guard.service';

import { IntegrationsMonitorComponent } from '../company/components/integrations-monitor/integrations-monitor.component';
import { ThemeResolver } from '../theme/theme.resolver';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  {
    path: 'admin',
    resolve: { theme: ThemeResolver }, runGuardsAndResolvers: 'always',
    canActivate: [AdminGuard],
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'companies', pathMatch: 'full' },
      { path: 'companies', loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule) },
      { path: 'permissions', loadChildren: () => import('./modules/permissions/permissions.module').then(m => m.PermissionsModule) },
      { path: 'fintech', loadChildren: () => import('./modules/fintech/fintech.module').then(m => m.FintechModule) },
      { path: 'markets/orders', loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule) },
      { path: 'templates', loadChildren: () => import('./modules/templates-notifications/templates-notifications.module').then(m => m.TemplatesNotificationsModule) },
      { path: 'identity-validation', loadChildren: () => import('./modules/identity-validation/identity-validation.module').then(m => m.IdentityValidationModule) },
      { path: 'ciec', loadChildren: () => import('./modules/ciec/ciec.module').then(m => m.CiecModule) },
      { path: 'nip', loadChildren: () => import('./modules/nip/nip.module').then(m => m.NipModule) },
      { path: 'tenants', loadChildren: () => import('./modules/tenants/tenants.module').then(m => m.TenantsModule) },
      { path: 'destinations', loadChildren: () => import('./modules/destinations/destinations.module').then(m => m.DestinationsModule) },
      { path: 'integrations-monitor', component: IntegrationsMonitorComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AdminRoutingModule { }
