<div toastContainer></div>

<div *ngIf="browsing" class="app-browsing no-select">
	<div *ngIf="browsing" class="indeterminate"></div>
</div>

<div *ngIf="progress" class="app-progress no-select"
[style.width]="progress + '%'">
</div>

<div class="app-header">
  <header></header>
  <navbar></navbar>
</div>

<div class="app-content" #mainFrame id="agree-main">
  <router-outlet></router-outlet>
</div>

<!-- <ng-template #modal>
  <div class="modal-body text-center">
    <i class="material-symbols-rounded faded text-muted" style="font-size: 800%;">campaign</i>
    <h3>Nuestro dominio es: <b>agree.ag</b></h3>
    <p><code>agreemarket.com</code> redirigirá automáticamente a partir del <b>1 de Febrero de 2024</b>.</p>
    <p class="small text-muted">Por favor, actualice sus marcadores.</p>
  </div>
  <div class="modal-footer">
    <a class="btn btn-lg btn-agree btn-block" href="{{ document.location.href.replace('agreemarket.com', 'agree.ag') }}">
      Continuar al sitio
    </a>
  </div>
</ng-template> -->

<ng-template #modalUpdate>
  <!-- modal -->
  <div class="modal-body text-center">
    <i class="material-symbols-rounded faded text-muted" style="font-size: 800%;">release_alert</i>
    <h3>Nueva versión disponible!</h3>
    <p>Para aplicar todas las novedades es necesario <b>recargar la aplicación</b>.</p>
  </div>
  <div class="modal-footer">
    <a class="btn btn-lg btn-agree btn-block" (click)="refresh()">
      <span class="material-symbols-rounded">refresh</span>
      Recargar la aplicación
    </a>
  </div>
</ng-template>