import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ComponentCommService } from '../../../services/component-comm.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { FlashService } from '../../../ui/services/flash.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  @ViewChild(NgForm, { static: true }) private readonly form: NgForm;

  private token: string;
  private id_user: number;
  private result: {
    token_valid: boolean,
    user: { id: number }
  };

  public sending = false;
  public validation = 'pending';

  constructor(
    private router: Router,
    private componentComm: ComponentCommService,
    private activatedRoute: ActivatedRoute,
    private flashService: FlashService,
    private loginService: LoginService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.token = this.activatedRoute.snapshot.params.token;
  }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'UPDATE_PASSWORD.TITLE' });

    this.loginService.getPasswordVerification(this.token).subscribe({
      next: params => {
        this.validation = 'succeeded';
        this.result = params;
        this.id_user = this.result.user.id;
      },
      error: error => {
        this.validation = 'failed';
        this.flashService.report('password_invalid_token');
        this.router.navigate(['login']);
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  public send_data = {
    password: '',
    password_confirmation: '',
    id: undefined,
    token: undefined
  };

  public submit(): void {
    this.form.control.setErrors({
      "remote": false
    });

    // TODO: Check password confirm
    this.send_data = Object.assign(this.send_data, { 'id': this.id_user }, { 'token': this.token });

    this.sending = true;
    this.loginService.changePassword(this.send_data).subscribe({
      next: response => {
        this.sending = false;
        this.flashService.report('password_updated');
        this.router.navigate(['login']);
      },
      error: error => {
        this.sending = false;
        this.form.control.setErrors({
          "remote": true
        });
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });

  }
}
