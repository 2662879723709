import { Type } from "class-transformer";

export class Integration {
  readonly id: number;

  name: string;

  @Type(() => IntegrationResource)
  resources: IntegrationResource[];

  constructor(data: Partial<Integration> = {}) {
    Object.assign(this, data);
  }
}

export class IntegrationResource {
  name: string;

  @Type(() => Date)
  lastUpdate: Date;

  get status(): "error" | "success" | "warning" {
    if (!this.lastUpdate) return "error";

    const daysOld = this.daysOld(this.lastUpdate);
    if (daysOld < 4) return "success";
    if (daysOld < 7) return "warning";
    return "error";
  }

  private daysOld(date: Date): number {
    const nowDate = new Date();
    const DAY = (1000 * 3600 * 24);
    const difference = nowDate.getTime() - date.getTime();
    const totalDays = Math.ceil(difference / DAY);
    return totalDays;
  }

  constructor(data: Partial<IntegrationResource> = {}) {
    Object.assign(this, data);
  }
}
