import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiConfInterceptor } from './api-conf';

export let ApiProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: ApiConfInterceptor,
  multi: true
};
