<div class="ag-container">
    <div class="ag-col-secondary">
      <filter-set #filterSet="filterSet" [marketId]="marketId">
        <filter-list key="payment_condition"></filter-list>
        <filter-list key="operation_type"></filter-list>
        <filter-list key="order_type" *ngIf="market?.configuration.allowed_order_types.auction"></filter-list>
        <filter-list key="product_id" sortKey="name"></filter-list>
        <filter-list key="delivery_type"></filter-list>
        <!-- <filter-list key="zone_id" sortKey="name" *ngIf="market?.configuration.location.zone.enabled"></filter-list> -->
        <!-- <filter-select key="range"></filter-select> -->
      </filter-set>
    </div>
    <div class="ag-col-primary">
      <div class="container-fluid">
        <div class="content-box">
          <div class="ag-header sticky" *ngIf="ordersByProduct?.length > 0 && !loading">
            <div class="ag-header-title">
              <!-- View mode -->
              <!-- FAS-1789 -->
              <div id="viewmode-toggle" class="btn-group" data-toggle="buttons" *ngIf="market.configuration.order.allow_media || hasOrdersWithGeolocation">
                <!-- <label class="btn btn-default btn-sm" [class.active]="viewMode === 'expanded'" title="{{ 'WORKING_ORDERS.VIEWMODE.EXPANDED'|translate }}">
                  <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="expanded">
                  <i class="material-symbols-rounded">view_agenda</i>
                </label> -->
                <label class="btn btn-default btn-sm" [class.active]="viewMode === 'gallery'" title="{{ 'WORKING_ORDERS.VIEWMODE.GALLERY'|translate }}" *ngIf="market.configuration.order.allow_media">
                  <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="gallery">
                  <i class="material-symbols-rounded">view_module</i>
                </label>
                <label class="btn btn-default btn-sm" [class.active]="viewMode === 'table'" title="{{ 'WORKING_ORDERS.VIEWMODE.TABLE'|translate }}">
                  <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="table">
                  <i class="material-symbols-rounded">view_list</i>
                </label>
                <label class="btn btn-default btn-sm" [class.active]="viewMode === 'map'" title="{{ 'WORKING_ORDERS.VIEWMODE.MAP'|translate }}" *ngIf="hasOrdersWithGeolocation && (environment.maps.mapbox || environment.maps.google)">
                  <input name="viewmode" type="radio" [(ngModel)]="viewMode" value="map">
                  <i class="material-symbols-rounded">map</i>
                </label>&nbsp;&nbsp;
              </div>
              <div class="summary">
                <span [innerHtml]="'WORKING_ORDERS.SUMARIZE'|translate: {quantity: (ordersByProduct|pluck:'values'|flatten).length}"></span>
                <span *ngIf="market.quantity_units.length === 1 && totalTons > 0"
                [innerHtml]="'TRADED_VIEW.SUMARIZE_TONS'|translate: {
                  quantity: totalTons|number:undefined:currentLang,
                  unit: market.quantity_units[0].abbrev
                }"></span>
              </div>
            </div>
          </div>
  
          <div class="loading" *ngIf="loading">
            <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
          </div>
  
          <div class="row" *ngIf="!loading">
            <div class="col-xs-12">
              <!-- No data -->
  
              <div class="no-data" *ngIf="ordersByProduct?.length === 0">
                <i class="material-symbols-rounded">not_interested</i>
                {{ 'ORDER.NO_RECORDS'|translate }}
              </div>
  
              <orders-table *ngIf="viewMode === 'table'"
              [orders]="ordersByProduct"
              [market]="market"></orders-table>

              <div class="gallery-container" *ngIf="viewMode === 'gallery'">
                <ng-container *ngFor="let productGroup of ordersByProduct">
                  <div class="gallery-item" *ngFor="let order of productGroup.values" (click)="gotoLogin()">
                    <div class="gallery-card">
                      <div class="thumbs">
                        <media-browser [order-media]="order.media"></media-browser>
                        <!-- <img src="assets/img/sample/prod01.jpg"> -->
                        <div class="icons">
                          <!-- Auction -->
                          <div class="material-symbols-rounded" tooltip="{{ 'GLOBAL.AUCTION'|translate }}" placement="right" *ngIf="order.auction">gavel</div>
                        </div>
                        <!-- Price -->
                        <div class="price-box" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">
                          <div class="type">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
                          <div class="value">
                            <price-display [price]="order.business_detail.price" [market]="market" [withoutYear]="true"></price-display>
                          </div>
                        </div>
                      </div>
                      <!-- Quantity -->
                      <quantity-control *ngIf="!market.configuration.order.has_minimum_order" class="pull-right" [quantity]="order.business_detail.quantity"></quantity-control>
                      <div>
                        <b>{{ order.product.name }}</b>
                      </div>
                      <!-- Quality -->
                      <p class="small text-muted subtitle">
                        <span class="truncate">{{ order.quality_string }}</span>
                        <!-- Comments -->
                        <i class="material-symbols-rounded" *ngIf="order.general_observations" tooltip="{{ 'GLOBAL.OBSERVATIONS'|translate }}">comment</i>
                      </p>
                      <table class="company-table">
                        <tbody>
                          <tr>
                            <td class="logo">
                              <!-- Company logo -->
                              <company-logo></company-logo>
                            </td>
                            <td>
                              <!-- Company Name -->
                              <span class="not-available truncate">***</span>
                            </td>
                            <td class="text-right"><flag [country]="order.company?.address.country"></flag></td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="delivery-data flex-center-distributed">
                        <div class="small grow" *ngIf="order.business_detail.delivery.locations?.length" title="{{ 'GLOBAL.DELIVERY_PLACES'|translate }}">{{ order.business_detail.delivery.locations |locationString }}</div>
                        <div class="small grow truncate"*ngIf="order.business_detail.delivery.geolocations" title="{{ 'GLOBAL.DELIVERY_PLACES'|translate }}">
                          <ng-container *ngFor="let location of order.business_detail.delivery.geolocations; let last = last">
                            {{ location.name }}<span *ngIf="!last">, </span>
                          </ng-container>
                        </div>
                        <div class="small grow" *ngIf="market.configuration.order.business_detail.port.enabled" title="{{ 'GLOBAL.COUNTRY_PORT'|translate }}">{{ countryAndPort(order) }}</div>
                        <div class="text-right grow" *ngIf="market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" title="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}">
                          <b>{{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}</b>
                        </div>
                        <div title="{{ 'GLOBAL.PERIOD'|translate }}">
                          <!-- Delivery period -->
                          <date-range
                          [showSpot]="true"
                          [from]="order.business_detail.delivery.date_from"
                          [to]="order.business_detail.delivery.date_to"></date-range>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
  
              <!-- FAS-1791 -->
              <div class="map-container" *ngIf="viewMode === 'map' && ordersByProduct?.length > 0">
                <mapbox-view
                *ngIf="environment.maps.mapbox"
                [ordersByProduct]="ordersByProduct"></mapbox-view>
                <gmap-view
                *ngIf="environment.maps.google"
                [ordersByProduct]="ordersByProduct"></gmap-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="flyer no-select" *ngIf="market &&
    ('LANDING.'+[market.slug]+'.HEADLINE'|translate) !== 'LANDING.'+[market.slug]+'.HEADLINE'">
    <span [innerHtml]="'LANDING.'+[market.slug]+'.HEADLINE'|translate"></span>
    <div class="features">
      <div>{{ 'LANDING.'+[market.slug]+'.FEATURE_01'|translate }} <span class="glyphicon glyphicon-ok" aria-hidden="true"></span></div>
      <div>{{ 'LANDING.'+[market.slug]+'.FEATURE_02'|translate }} <span class="glyphicon glyphicon-ok" aria-hidden="true"></span></div>
      <div>{{ 'LANDING.'+[market.slug]+'.FEATURE_03'|translate }} <span class="glyphicon glyphicon-ok" aria-hidden="true"></span></div>
    </div>
  </div>