import { Component } from '@angular/core';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.css']
})
export class TrackComponent {

  public batch: any;

  constructor() { }

  public setBatch(v?: any): void {
    this.batch = v || undefined;
  }
}
