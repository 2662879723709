import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { DataDogLoggerService } from './data-dog-logger.service';

/**
 * Determines the default language for the current [[User]].
 */
@Injectable()
export class DefaultLanguageService {

  private languageEvent: BehaviorSubject<string>;
  private baseUrl: string = "/:apiServices/geolocations";
  private geoData: GeoData;
  private loading: boolean;

  constructor(
    private http: HttpClient,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    this.languageEvent = new BehaviorSubject(this.getBrowserLanguage());
  }

  /**
   * Retrieves the browser's language setting and matches it with the supported languages.
   * 
   * @returns {string} - The matched language slug or 'es' if no match is found.
   */
  private getBrowserLanguage(): string {
    let lang: string = window.navigator.language || (window.navigator as any).userLanguage;

    if (lang.length > 2) {
      lang = lang.split("-")[0];
    }

    const language = environment.languages.find(e => e.slug === lang);
    return language ? language.slug : 'es';
  }

  /**
   * Fetches geolocation data and emits the first valid language found.
   * If no valid language is found, no language is emitted.
   */
  private geolocatedLanguage(): void {
    if (!this.geoData && !this.loading) {
      this.loading = true;

      this.http.get<GeoData>(this.baseUrl).subscribe({
        next: response => {
          if (response.languages && response.languages.length) {
            this.geoData = response; // plainToInstance(GeoData, response);

            // Get valid languages
            const validLanguages = environment.languages.map(e => e.slug);
            const languages = this.geoData.languages.filter(value => validLanguages.includes(value));

            if (languages.length > 0) {
              // Emit geolocated language
              const language = languages[0];
              this.languageEvent.next(language);
            }

            this.languageEvent.complete();
          }
          this.loading = false;
        },
        error: error => {
          // Non-fatal error
          this.dataDogLoggerService.warn(error.message, error.error);
          this.loading = false;
        }
      });
    }
  }

  /**
   * To determine the [[User|User's]] language, the following logic will be
   * used: try to obtain the language immediately from the information provided
   * by the browser, secondly, consult a geolocation service that returns a
   * list of possible languages.
   * 
   * Only the languages available in the environment settings will be considered.
   * 
   * If all fails, the default language is Spanish (es).
   */
  public get(): BehaviorSubject<string> {
    this.geolocatedLanguage();
    return this.languageEvent;
  }
}

class GeoData {
  city: string;
  country: string;
  languages: string[];
  location: {
    lat: number,
    lon: number
  };
  region: string;
  timezone: string;
}