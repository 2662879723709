<section class="landing-bg">
  <div class="background-images">
    <div class="field w40"></div>
    <div class="teamwork w25"></div>
  </div>
  <div class="overlay"></div>
  <img class="dots" src="/assets/img/home/dots.png">
  <div class="gradient-circle"><img src="/assets/img/t.png"></div>
</section>

<section class="text-container">
  <div class="container-fluid">
    <div class="row header">
      <div class="col-xs-12 flex-center-distributed">
        <a class="logo" [routerLink]="['/']"></a>
        <div class="text-right">
          <a class="white" routerLink="/login" [innerHtml]="'SIGNUP.HAVE_ACCOUNT_ALREADY'|translate"></a>
          <span class="widther">&bull;</span>
          <span dropdown>
            <a href id="lang" dropdownToggle (click)="false" class="white text-uppercase small"
            aria-controls="lang-dropdown">{{ translateService.currentLang }}</a>
            <ul id="lang-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right"
            role="menu" aria-labelledby="lang">
              <li *ngFor="let lang of availableLanguages" [class.hidden]="translateService.currentLang === lang.slug"><a
              (click)="updateLanguage(lang.slug)">{{ lang.name }}</a></li>
            </ul>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 welcome-message text-center">
        <div class="main" [innerHtml]="'LANDING.MAIN'|translate"></div>
        <p class="secondary brand-color" [innerHtml]="'LANDING.SECONDARY'|translate"></p>
        <p class="tagline" [innerHtml]="'LANDING.TAGLINE'|translate"></p>

        <!-- <div class="icons flex-center-distributed hidden-xs">
          <div>
            <img src="/assets/img/home/ico-01.png">
            <div>{{ 'LANDING.COMMUNITY'|translate }}</div>
          </div>
          <div>
            <img src="/assets/img/home/ico-02.png">
            <div>{{ 'LANDING.MANAGEMENT'|translate }}</div>
          </div>
          <div>
            <img src="/assets/img/home/ico-03.png">
            <div>{{ 'LANDING.FUNDING'|translate }}</div>
          </div>
        </div> -->
      </div>
      <div class="col-md-1 curve">
        <svg class="visible-xs visible-sm" height="20" width="100%" viewBox="0 0 100 10" preserveAspectRatio="none">
          <path d="M 0 0 H 100 C 70 10, 30 10, 0 0"></path>
         </svg>
      </div>
      <div class="col-md-4">
        <div class="content-box">
          <form class="form-signin" (ngSubmit)="signUpForm.valid && signUp()" #signUpForm="ngForm">
            <h3 [innerHtml]="'SIGNUP.HEADING'|translate"></h3>
            <p class="help-block" [innerHtml]="'SIGNUP.HELP_BLOCK'|translate">
            </p>
            <div class="form-group" hasError>
              <label for="signupName" class="control-label">
                {{ 'GLOBAL.NAME'|translate }}
              </label>
              <input id="signupName" type="text" class="form-control input-lg" autofocus name="name"
              maxlength="256"
              [(ngModel)]="user.name" required>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>
            <div class="form-group" hasError>
              <label for="signupLastName" class="control-label">
                {{ 'SIGNUP.LAST_NAME'|translate }}
              </label>
              <input id="signupLastName" type="text" class="form-control input-lg" name="lastName"
              maxlength="256"
              [(ngModel)]="user.last_name" required>
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>
            <div class="form-group" hasError>
              <label for="signupEmail" class="control-label">
                {{ 'SIGNUP.EMAIL'|translate }}
              </label>
              <input id="signupEmail" type="email" class="form-control input-lg" name="email" [(ngModel)]="user.email" required email [disabled]="with_mail">
              <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
              <error-list></error-list>
            </div>

            <fieldset ngModelGroup="passwordGroup" [matches]="['password', 'confirmation']" hasError>
              <div class="form-group" hasError>
                <label for="signupPassword" class="control-label">
                  {{ 'SIGNUP.PASSWORD'|translate }}
                </label>
                <input id="signupPassword" type="password" class="form-control input-lg" name="password" [(ngModel)]="user.password" required
                #password="ngModel"
                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$">
                <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
                <password-strength-meter [password]="password.valid ? user.password : ''"></password-strength-meter>
                <span class="help-block small no-margin" [innerHtml]="'SIGNUP.PASSWORD_HELP'|translate"></span>
                <error-list></error-list>
              </div>
              <div class="form-group" hasError>
                <label for="signupConfirmation" class="control-label">
                  {{ 'SIGNUP.PASSWORD_CONFIRM'|translate }}
                </label>
                <input id="signupConfirmation" type="password" class="form-control input-lg" name="confirmation" [(ngModel)]="user.password_confirmation" required>
                <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
                <error-list></error-list>
              </div>
              <error-list></error-list>
            </fieldset>

            <alert type="danger" [dismissible]="true" *ngIf="form.invalid && form.submitted && form.errors?.remote">
              {{ 'SIGNUP.INVALID_FORM_ERROR'|translate }}
            </alert>

            <alert type="danger" [dismissible]="true" *ngIf="form.invalid && form.submitted && form.errors?.not_unique_email">
              {{ 'SIGNUP.EMAIL_EXISTS_ERROR'|translate }}
              <a routerLink="/reset-password" class="alert-link">
                {{ 'LOGIN.FORGOT_PASSWORD'|translate }}
              </a>
            </alert>

            <alert type="danger" [dismissible]="true" *ngIf="form.invalid && form.submitted && form.errors?.unknown">
              {{ 'SIGNUP.UNKNOWN_ERROR'|translate }}
            </alert>

            <div class="row">
              <div class="col-md-6 small text-muted">
                <p [innerHtml]="'SIGNUP.DISCLAIMER'|translate"></p>
              </div>
              <div class="col-md-6">
                <button class="btn btn-lg btn-agree btn-block" type="submit" [disabled]="sending">
                  {{ 'SIGNUP.CREATE_ACCOUNT'|translate }}
                </button>    
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <footer class="text-center small">
    <!-- <a class="agree-color" routerLink="/login">{{ 'GLOBAL.HAVE_ACCOUNT_ALREADY'|translate }}</a>
    <span class="widther">&bull;</span> -->
    <a class="white" routerLink="/terms">{{ 'GLOBAL.TERMS_AND_CONDITIONS'|translate }}</a>
    <span class="widther">&bull;</span>
    <a class="white" routerLink="/privacy">{{ 'GLOBAL.PRIVACY_POLICY'|translate }}</a>
    <span class="widther">&bull;</span>
    <a class="white" href="https://www.agree.ag/cookies/index.html">Cookies</a>
  </footer>
</section>