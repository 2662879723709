<section class="landing">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12">
        <div class="content-box">
          <batch-finder (onChange)="setBatch($event)"></batch-finder>

          <div
          [collapse]="!batch">
            <visual-tracker [batch]="batch"></visual-tracker>  
          </div>
        </div>
      </div>
    </div>
    <footer>
      <p class="text-center text-muted small">
        <a class="agree-color" routerLink="/reset-password">{{ 'DOCUMENTS.ENTER'|translate }}</a>
        <span class="widther">&bull;</span>
        <a class="agree-color" routerLink="/terms">{{ 'GLOBAL.TERMS_AND_CONDITIONS'|translate }}</a>
        <span class="widther">&bull;</span>
        <a class="agree-color" routerLink="/privacy">{{ 'GLOBAL.PRIVACY_POLICY'|translate }}</a>
        <span class="widther">&bull;</span>
        <a class="agree-color" href="https://www.agree.ag/cookies/index.html">Cookies</a>
      </p>
    </footer>
  </div>
</section>
