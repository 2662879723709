import { Type } from "class-transformer";

import { Company } from "./company.model";

export class Notification {
  /** Agree's internal unique identifier. */
  readonly id: string;

  read: boolean;

  readonly subject: string;
  readonly message: string;
  readonly link: string;
  // readonly key: string;
  readonly data: any;
  readonly userId: number;

  // @Type(() => Company)
  readonly company: Company;

  @Type(() => Date)
  readonly createdAt: Date;
}
