<div *ngIf="company && user; else loading" class="container">
    <div class="row text-center">
        <div class="col-md-6 col-md-offset-3">
            <div class="organizations">
                <div [style.backgroundImage]="(logoBaseUrl + company.logo) | trustStyleUrl" class="organization-logo">
                </div>
                <div class="organization-linker-draw">
                    <span class="material-symbols-rounded">
                        done
                    </span>
                </div>
                <div class="organization-logo logo-agree"></div>
            </div>
            <h3 style="padding: 10px" [innerHTML]="'CLIENT_CREDENTIALS.OAUTH.TITLE'|translate:{company: company.name}">
            </h3>

            <div class="content-box">
                <div class="modal-header">
                    <h4 class="text-bold">{{'CLIENT_CREDENTIALS.OAUTH.LABEL'|translate}}</h4>
                </div>
                <div class="modal-body">
                    <p [innerHTML]="'CLIENT_CREDENTIALS.OAUTH.DESCRIPTION'|translate:{company: company.name}"></p>
                </div>
                <div class="modal-footer">
                    <button *ngIf="!redirecting" (click)="authorize()" [disabled]="processing || redirecting" class="btn btn-lg btn-block"
                        [class.btn-agree]="!redirecting"><span *ngIf="!redirecting">{{'CLIENT_CREDENTIALS.OAUTH.AUTHORIZE'|translate}}</span>
                    </button>
                    <p *ngIf="!redirecting" class="text-muted text-center"
                        [innerHTML]="'CLIENT_CREDENTIALS.OAUTH.REDIRECT_TO'|translate:{url:oAuthParamsData.callback_uri}">
                        <small></small></p>
                    <spinner *ngIf="redirecting"
                        [message]="'CLIENT_CREDENTIALS.OAUTH.REDIRECTING'|translate"
                        [skipZoomIn]="true"></spinner>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="loading">
        <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
    </div>
</ng-template>