import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ComponentCommService } from '../../../services/component-comm.service';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { FlashService } from '../../../ui/services/flash.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild(NgForm, { static: true }) private readonly form: NgForm;

  public sending: boolean;

  constructor(
    private router: Router,
    private componentComm: ComponentCommService,
    private flashService: FlashService,
    private loginService: LoginService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'PASSWORD_RESET.HEADING' });
  }

  public user_email: string = '';

  public verification_email: boolean;
  public generic_error: boolean;
  public regenerate_token: boolean;

  public submit(): void {
    this.form.control.setErrors({
      "remote": false
    });

    this.sending = true;
    this.generic_error = false;

    this.loginService.getRequestPassword({
      email: this.user_email
    }).subscribe({
      next: response => {
        this.sending = false;
        this.flashService.report('password_reset');
        this.router.navigate(['login']);
        this.verification_email = false;
      },
      error: error => {
        this.sending = false;
        let message = error.error.message;
        if (message) {
          if (message.ext_email_not_verified) {
            this.verification_email = true;
          }

          // TODO: generic error merging
          if (message.inputs.email) {
            if (message.inputs.email.Exists) {
              this.form.controls.email.setErrors({
                "exists": true
              });
            }
          } else {
            this.generic_error = true;
          }
        }
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  public resend(email_input: string): void {
    this.loginService.resendVerification(email_input).subscribe({
      next: response => {
        this.regenerate_token = true;
      },
      error: error => {
        this.sending = false;
        let message = error.error.message;
        // TODO: generic error merging
        if (message.inputs.email) {
          if (message.inputs.email.Exists) {
            this.form.controls.email.setErrors({
              "exists": true
            });
          }
        }
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  public emailChange(): void {
    this.verification_email = false;
    this.generic_error = false;
  }
}
