<app-message-modal
#messageModal></app-message-modal>

<li class="small">
  <table class="table no-margin">
    <tr>
      <td><b>{{ 'INBOX.NOTIFICATIONS'|translate }}</b></td>
      <td class="text-right">
        <a *ngIf="messageDropdown.notifications.length > 0" (click)="setAllRead()">
          {{ 'INBOX.MARK_ALL_AS_READ'|translate }}
        </a>
      </td>
    </tr>
  </table>
</li>
<li role="separator" class="divider nomargin-bottom"></li>
<li class="messages">
  <div class="no-data" *ngIf="messageDropdown.notifications.length === 0">
    <i class="material-symbols-rounded">mail_outline</i>
    {{ 'INBOX.EMPTY_UNREAD'|translate }}
  </div>
  <table *ngIf="messageDropdown.notifications.length > 0">
    <tr *ngFor='let message of messageDropdown.notifications'>
      <td
      (click)="openMessage(message)"
      [class.read]="message.read"
      [class.processing]="messageDropdown.processing === message || messageDropdown.processingAll">
        <div class="micro text-muted flex-center-distributed">
          <div class="grow">
            <span clickStop>
              <span class="icons" (click)="toggleRead(message)">
                <img class="read" src="/assets/img/icon-email-open.svg" tooltip="{{ 'INBOX.MARK_AS_UNREAD'|translate }}">
                <i class="unread material-symbols-rounded" tooltip="{{ 'INBOX.MARK_AS_READ'|translate }}">email</i>
              </span>
            </span>
            <span *ngIf="message.company?.name">
              &nbsp;<span class="text-capitalize">{{ 'INBOX.TO'|translate }}</span>&nbsp;<b>{{ message.company.name }}</b>
            </span>
          </div>
          <div>
            <ng-container
              *ngIf="((message.createdAt | date:('GLOBAL.DATE_FORMAT.RG'|translate)) !== (today | date:('GLOBAL.DATE_FORMAT.RG'|translate)))">
              {{ message.createdAt | date:('GLOBAL.DATE_FORMAT.SM'|translate)}}, 
            </ng-container>
            {{ message.createdAt | date:'HH:mm'}}
          </div>
        </div>
        <div class="subject small" *ngIf="message.subject" [innerHTML]="message.subject"></div>
        <!-- <div class="message" *ngIf="message.message">{{ message.message | stripTags | shorten: 140: '...': false }}</div>  -->
      </td>
    </tr>
  </table>
</li>
<li role="separator" class="divider nomargin-top"></li>
<li class="small text-center">
  <span><a [routerLink]="['/messages']">{{ 'INBOX.SEE_ALL'|translate }}</a></span>
</li>
