import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { Notification } from '../../models/notification.model';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.css']
})
export class MessageModalComponent implements OnDestroy {

  @ViewChild('messageModal', { static: true }) private readonly messageModal: TemplateRef<any>;

  public notification: Notification;

  private modalRef: BsModalRef[];

  constructor(
    private modalService: BsModalService
  ) { }

  public show(notification: Notification): void {
    if (notification) {
      this.notification = notification;
      this.openModal(this.messageModal);
    }
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    const newModal = this.modalService.show(template, { class: c });

    this.modalRef = [...(this.modalRef || []), newModal];
    newModal.onHide.subscribe(() => {
      // Removes last modal reference
      this.modalRef.pop();
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    const mr = this.modalRef[this.modalRef.length - 1];

    if (mr) {
      mr.hide();
      if (onHide) mr.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.modalRef?.forEach(modal => modal.hide());
  }
}
