import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { User } from '../../auth/models/user.model';
import { LoginService } from '../../auth/services/login.service';
import { ComponentCommService } from '../../services/component-comm.service';
import { MarketService } from '../../services/market.service';
import { HTMLToClipboardService } from '../../ui/services/html-to-clipboard.service';

/**
 * Documents is the component in charge of displaying the documentation of the
 * specific platform of each market, including the Terms and Conditions,
 * Privacy Policies, etc.
 */
@Component({
  selector: 'documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

  public marketsByType: Array<any>;
  /** Current [[User]]. */
  public user: User;
  public marketId: string;
  public documentType: string;
  public documentsList: any;
  /** The language currently used. */
  public currentLang: string;
  private subscriptions: Subscription[] = [];

  /** @ignore */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private marketService: MarketService,
    private loginService: LoginService,
    private componentComm: ComponentCommService,
    public htmlClipboardService: HTMLToClipboardService,
    private translateService: TranslateService
  ) {
    this.documentsList = {
      'terms': 'GLOBAL.TERMS_AND_CONDITIONS',
      'privacy': 'GLOBAL.PRIVACY_POLICY',
      // 'user-manual': 'GLOBAL.USER_MANUAL',
      // 'fees': 'GLOBAL.FEES'
    };
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;

    this.subscriptions.push(this.route.params.subscribe(params => {
      if (this.documentsList[params.documentType]) {
        this.marketId = params.marketId;
        this.documentType = params.documentType;
        this.componentComm.emit({ name: 'app-title', title: this.documentsList[this.documentType] });
      } else {
        // If slug does not exists, defaults to terms
        this.router.navigate(['documents', 'terms', params.marketId]);
      }
    }));

    this.subscriptions.push(this.marketService.getMarketsByType().subscribe(markets => {
      this.marketsByType = markets;
    }));

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    }));
  }

  public goto(id): void {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
