import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { MessageDropdownDirective } from '../../directives/message-dropdown.directive';
import { Notification } from '../../models/notification.model';
import { CurrentDateService } from '../../services/current-date.service';
import { MessageModalComponent } from '../message-modal/message-modal.component';

@Component({
  selector: '[messageMenu]',
  exportAs: 'messageMenu',
  templateUrl: './message-menu.component.html',
  styleUrls: ['./message-menu.component.scss']
})
export class MessageMenuComponent {

  @ViewChild('messageModal', { static: true }) private readonly messageModal: MessageModalComponent;

  public today: Date = this.currentDate.get();

  constructor(
    private currentDate: CurrentDateService,
    public messageDropdown: MessageDropdownDirective,
    private router: Router
  ) { }

  toggleRead(notification: Notification) {
    this.messageDropdown.toggleRead(notification);
  }

  setAllRead(): void {
    this.messageDropdown.setAllRead();
  }

  openMessage(notification: Notification) {
    this.messageDropdown.setRead(notification);

    if (notification.link) {
      this.router.navigateByUrl(notification.link);
    } else {
      this.messageModal.show(notification);
    }
  }
}
