import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { User } from '../../auth/models/user.model';
import { LoginService } from '../../auth/services/login.service';
import { Notification } from '../../models/notification.model';
import { ComponentCommService } from '../../services/component-comm.service';
import { CurrentDateService } from '../../services/current-date.service';
import { DataDogLoggerService } from '../../services/data-dog-logger.service';
import { NotificationService } from '../../services/notification.service';
import { ThemeService } from '../../theme/services/theme.service';
import { MessageModalComponent } from '../message-modal/message-modal.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

  @ViewChild('messageModal', { static: true }) private readonly messageModal: MessageModalComponent;

  public notifications: Notification[];
  public processing: Notification;
  /** Flag used to indicate if the component is loading information. */
  public loading: boolean = true;
  public today: Date = this.currentDate.get();

  private lastId: string;
  private subscriptions: Subscription[] = [];
  private user: User;

  constructor(
    public themeService: ThemeService,
    private componentComm: ComponentCommService,
    private currentDate: CurrentDateService,
    private router: Router,
    private messageService: NotificationService,
    private loginService: LoginService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'INBOX.NOTIFICATIONS' });

    this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
      this.setup();
    });
  }

  private setup(): void {
    this.subscriptions.push(this.messageService.get(this.user.id).subscribe(response => {
      this.lastId = response.lastId;
      this.notifications = response.notifications;
      this.loading = false;
    }));
  }

  public onScroll(): void {
    if (this.lastId) {
      this.subscriptions.push(this.messageService.get(this.user.id, this.lastId).subscribe(response => {
        this.lastId = response.lastId;
        this.notifications = this.notifications.concat(response.notifications);
      }));
    }
  }

  public open(notification: Notification): void {
    this.processing = notification;
    this.messageService.setRead(notification).subscribe({
      next: response => {
        this.processing = null;
      },
      error: error => {
        // Non fatal error
        this.dataDogLoggerService.warn(error.message, error.error);
        this.processing = null;
      }
    });

    if (notification.link) {
      this.router.navigateByUrl(notification.link);
    } else {
      this.messageModal.show(notification);
    }
  }

  public toggleRead(notification: Notification): void {
    this.processing = notification;
    this.messageService.toggleRead(notification).subscribe({
      next: response => {
        this.processing = null;
      },
      error: error => {
        // Non fatal error
        this.dataDogLoggerService.warn(error.message, error.error);
        this.processing = null;
      }
    });
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
