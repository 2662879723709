import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './auth/components/login/login.component';
import { OAuthComponent } from './auth/components/oauth/oauth.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { SignUpComponent } from './auth/components/sign-up/sign-up.component';
import { UpdatePasswordComponent } from './auth/components/update-password/update-password.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { CompaniesComponent } from './companies/companies.component';
import { RedirectPublicApiComponent } from './company/components/redirect-public-api/redirect-public-api.component';
import { MarketSummaryResolver } from './company/modules/commercial/services/marketsummary-resolver.service';
import { ApprovalsComponent } from './company/modules/fintech/components/approvals/approvals.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { MessagesComponent } from './components/messages/messages.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SelectMarketComponent } from './components/select-market/select-market.component';
import { TrackComponent } from './components/track/track.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ThemeResolver } from './theme/theme.resolver';

const routes: Routes = [
  {
    path: '', resolve: { theme: ThemeResolver }, runGuardsAndResolvers: 'always', children: [
      { path: '', redirectTo: '/companies', pathMatch: 'full' },
      { path: 'sign-up', component: SignUpComponent },
      { path: 'users/:userId/verification/:token', component: EmailVerificationComponent },
      { path: 'login', component: LoginComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'update-password/:token', component: UpdatePasswordComponent },
      { path: 'user', component: UserProfileComponent },
      { path: 'user/:section', component: UserProfileComponent },
      { path: 'select-market', component: SelectMarketComponent },
      { path: 'oauth', component: OAuthComponent },
      { path: 'terms', redirectTo: 'documents/terms/ar', pathMatch: 'full' },
      { path: 'privacy', redirectTo: 'documents/privacy/ar', pathMatch: 'full' },
      { path: 'fees', redirectTo: 'documents/fees/ar', pathMatch: 'full' },
      { path: 'user-manual', redirectTo: 'documents/user-manual/ar', pathMatch: 'full' },
      { path: 'documents/:documentType/:marketId', component: DocumentsComponent },
      {
        path: 'companies',
        canActivate: [AuthGuard],
        component: CompaniesComponent
      },
      {
        path: 'messages',
        canActivate: [AuthGuard],
        component: MessagesComponent
      },
      {
        path: 'approvals',
        canActivate: [AuthGuard],
        component: ApprovalsComponent,
      },
      { path: 'track', component: TrackComponent },
      {
        path: 'redirect',
        children: [
          {
            path: '**',
            component: RedirectPublicApiComponent,
          },
        ],
      },
      { path: '**', component: PageNotFoundComponent }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule],
  providers: [
    MarketSummaryResolver,
    ThemeResolver
  ]
})
export class AppRoutingModule { }
