import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Notification } from '../models/notification.model';
import { DataDogLoggerService } from '../services/data-dog-logger.service';
import { NotificationService } from '../services/notification.service';

@Directive({
  selector: '[messageDropdown]',
  exportAs: 'messageDropdown'
})
export class MessageDropdownDirective implements OnInit, OnDestroy {

  @Input() private userId: number;

  public processing: Notification;
  public processingAll: boolean;
  public notifications: Notification[] = [];
  public total: number;

  private subs: Subscription;

  constructor(
    private messageService: NotificationService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.subs = this.messageService.watchUnread(this.userId).subscribe({
      next: notifications => {
        this.notifications = notifications;
        this.total = notifications.length;
      },
      error: error => {
        // Non fatal error
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  public toggleRead(notification: Notification): void {
    this.processing = notification;
    this.messageService.toggleRead(notification).subscribe({
      next: response => {
        this.processing = null;
      },
      error: error => {
        // Non fatal error
        this.dataDogLoggerService.warn(error.message, error.error);
        this.processing = null;
      }
    });
  }

  public setAllRead(): void {
    this.processingAll = true;
    this.messageService.setAllRead(this.userId).subscribe(() => {
      this.processingAll = false;
    });
  }

  public setRead(notification: Notification): void {
    this.messageService.setRead(notification).subscribe({
      error: error => {
        // Non fatal error
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
}
