import { Injectable } from '@angular/core';

/** Keeps a record of the page visited. */
@Injectable({
  providedIn: 'root'
})
export class NavigationHistoryService {

  private navigationHistory: string[];
  /** Maximum URLs to be saved in history. */
  private maxLength: number = 20;

  constructor() {
    this.navigationHistory = [];
  }

  /** Add a URL to the history. */
  public add(url: string): void {
    if (url) {
      this.navigationHistory.push(url);
      if (this.navigationHistory.length > this.maxLength) {
        this.navigationHistory.shift();
      }
    }
  }

  /** Returns all history in memory. */
  public get(): string[] {
    return this.navigationHistory;
  }
}
