<app-message-modal
#messageModal></app-message-modal>

<div class="container"
infiniteScroll
[infiniteScrollDistance]="2"
infiniteScrollContainer="#agree-main"
[fromRoot]="true"
(scrolled)="onScroll()">
  <div class="content-box">
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>{{ 'INBOX.NOTIFICATIONS'|translate }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>

        <div class="no-data" *ngIf="!loading && notifications?.length === 0">
          <i class="material-symbols-rounded">not_interested</i>
          {{ 'INBOX.EMPTY'|translate }}
        </div>

        <table class="table table-inbox" *ngIf="!loading && notifications?.length > 0">
          <thead>
            <tr>
              <th class="icons"></th>
              <th></th>
              <th class="date">{{ 'GLOBAL.DATE'|translate }}</th>
            </tr>
          </thead>
          <tbody>
            <!-- Message row -->
            <tr *ngFor="let notification of notifications"
              [class.read]="notification.read"
              [class.processing]="processing === notification">
              <td class="icons" (click)="toggleRead(notification)">
                <img class="read" src="/assets/img/icon-email-open.svg" tooltip="{{ 'INBOX.MARK_AS_UNREAD'|translate }}">
                <i class="unread material-symbols-rounded" tooltip="{{ 'INBOX.MARK_AS_READ'|translate }}">email</i>
              </td>
              <td colspan="2" class="pointer" (click)="open(notification)">
                <div class="header">
                  <span class="companies">
                    <span class="from">{{ themeService.theme.title }}</span>
                    <span *ngIf="notification.company?.name">
                      {{ 'INBOX.TO'|translate }}
                      <span class="to">{{ notification.company.name }}</span>
                    </span>
                  </span>
                  <span class="date">
                    <ng-container
                      *ngIf="((notification.createdAt | date:('GLOBAL.DATE_FORMAT.RG'|translate)) !== (today | date:('GLOBAL.DATE_FORMAT.RG'|translate)))">
                      {{ notification.createdAt | date:('GLOBAL.DATE_FORMAT.SM'|translate)}}, 
                    </ng-container>
                    {{ notification.createdAt | date:'HH:mm'}}
                  </span>
                </div>
                <div class="subject" *ngIf="notification.subject" [innerHTML]="notification.subject"></div>
                <div class="message" *ngIf="notification.message">{{ notification.message | stripTags | shorten: 140: '...': false }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
