<div class="container">
  <div class="content-box">
    <!-- <ol class="breadcrumb">
      <li class="active">{{ "INTEGRATIONS.MONITOR" | translate }}</li>
    </ol> -->
    <div class="ag-header sticky">
      <div class="ag-header-title">
        <h3>{{ "INTEGRATIONS.MONITOR" | translate }}</h3>
      </div>
    </div>

    <div *ngIf="!processing; else loading" class="row">
      <div *ngFor="let integration of integrations" class="column card-box">
        <h5 class="box-title">{{ integration.name }}</h5>
        <table class="table table-hover table-condensed">
          <thead>
            <tr>
              <th class="sap">Modulo</th>
              <th class="sap">Ultima Act.</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let module of integration.resources">
              <td>
                <status-indicator
                  [status]="module.status"
                  style="margin: 3px"
                ></status-indicator>
                {{ module.name }}
              </td>
              <td><date-md [date]="module.lastUpdate"></date-md></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING' | translate }}"></spinner>
  </div>
</ng-template>
