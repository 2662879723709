import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { DEFAULT_PSM_OPTIONS } from 'angular-password-strength-meter/zxcvbn';

import { UiModule } from '../ui/ui.module';
import { LoginComponent } from './components/login/login.component';
import { OAuthComponent } from './components/oauth/oauth.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { AdminGuard } from './services/admin-guard.service';
import { AuthGuard } from './services/auth-guard.service';
import { LoginService } from './services/login.service';

@NgModule({
  declarations: [
    SignUpComponent,
    UpdatePasswordComponent,
    LoginComponent,
    ResetPasswordComponent,
    OAuthComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterModule.forRoot(DEFAULT_PSM_OPTIONS),
    HttpClientModule,
    RouterModule,
    UiModule
  ],
  providers: [
    LoginService,
    AuthGuard,
    AdminGuard
  ],
  exports: [
    SignUpComponent,
    UpdatePasswordComponent,
    LoginComponent,
    ResetPasswordComponent
  ]
})
export class AuthModule { }
