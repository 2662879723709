import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, combineLatest, debounceTime } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Account } from '../../auth/models/account.model';
import { Company } from '../../models/company.model';
import { CompanyService } from '../../services/company.service';
import { PusherService } from '../../services/pusher.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  @ViewChild('nav') private readonly nav: ElementRef;

  private account: Account;
  private company: Company;
  private subscriptions: Subscription[] = [];
  private notificationsSub: Subscription;

  /** Currently active [[NavTab]]. */
  public activeNavTab: NavTab;
  public menuStack: NavTab[];

  /** @ignore */
  constructor(
    private companyService: CompanyService,
    private pusherService: PusherService,
    private router: Router
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.detectActiveTab();
      }
    }));

    this.subscriptions.push(combineLatest([
      this.companyService.watchAccount(),
      this.companyService.watch()
    ]).pipe(
      debounceTime(100)
    ).subscribe(response => {
      // Destructures the values ​​received from the observables, for readability
      const [
        account,
        company,
      ] = response;

      if (this.notificationsSub) this.notificationsSub.unsubscribe();

      if (company?.id) {
        this.notificationsSub = this.pusherService.listen('company_' + company.id, 'notification', 1000).subscribe(message => {
          this.companyService.checkCurrent(true);
        });
      }

      this.account = account;
      this.company = company;
      this.buildMenu();
    }));
  }

  /**
   * Makes an horizontal scroll so that the active tab is visible on the
   * screen.
   */
  private scrollToActive(): void {
    const element: HTMLElement = document.querySelector('nav > div.tablist > ul.inner > li.tablist-tab.active');
    if (element) {
      this.nav.nativeElement.scrollLeft = element.offsetLeft - parseInt(window.getComputedStyle(document.querySelector('.tablist .inner'), null).getPropertyValue('padding-left'));
    }
  }

  /**
   * Based on the current URL it determines which of the tabs is active.
   */
  private detectActiveTab(): void {
    if (this.company && this.menuStack) {
      const currentUri = this.router.url.split("?")[0];

      if (currentUri.indexOf('/company/' + this.company.id) === 0) {
        const activeNavTab: NavTab = this.menuStack.find(nt => (nt.activeInSub) ? currentUri.indexOf(nt.router_link) === 0 : nt.router_link === currentUri);

        this.activeNavTab = activeNavTab;

        if (this.activeNavTab) setTimeout(() => {
          this.scrollToActive();
        });
      }
    }
  }

  /**
   * Adds a tab to the navigation bar.
   *
   * @param label Tab label (dictionary key)
   * @param route
   * @param query_params
   * @param icon
   * @param activeInSub Whether it should remain active in sub-routes
   */
  private addNavTab(label: string, route: string, query_params: any = null, icon?: string, activeInSub?: boolean): void {
    const { is } = this.account;
    /**
     * Routes restricted by role.
     * Undefined routes are available to all.
     */
    const routesByRoles: { [route: string]: boolean } = {
      '/market': is.commercial,
      '/company-traded-orders': is.commercial,
      '/working-orders': is.commercial,
      '/my-operations': is.commercial,
      '/traded-orders': is.commercial,
      '/preorders': is.commercial,
      '/barters': is.commercial,
      '/slots': is.commercial || is.logistics || is.operations || is.business_officer || is.counterpart_admin,
      '/fintech': is.commercial || is.financial,
      '/imported-data': is.commercial || is.logistics || is.operations || is.financial,
      '/contracts': is.commercial || is.logistics || is.operations || is.financial
    };

    if (routesByRoles[route] !== false) {
      this.menuStack.push(new NavTab({
        icon: icon,
        label: label,
        router_link: '/company/' + this.company.id + route,
        query_params: query_params,
        activeInSub: activeInSub,
        id: this.menuStack.length + 1
      }));
    }
  }

  private buildMenu(): void {
    this.menuStack = [];

    if (this.company && this.account) {

      this.addCommercialNavItems();

      const isFullyActivated = this.company.activation_level.id === 2;
      if (isFullyActivated) {
        this.addSlotNavItem();
        this.addBartersNavItem();
        this.addSignaturesNavItem();
        this.addFintechNavItem();
        this.addStatisticsNavItem();
      }

      this.addImportedDataNavItem();

      this.detectActiveTab();
    }
  }

  private addCommercialNavItems(): void {
    if (!this.company.hasModule('commercial')) return;

    // Ordenes compra venta
    this.addNavTab('COMPANY.WORKING_ORDERS', '/working-orders');

    // Mis Operaciones
    this.addNavTab('COMPANY.MY_OPERATIONS', '/my-operations', {
      status_group_orders: 1,
      order_by: '-updated_at'
    });

    // Negocios de mi empresa
    this.addNavTab('COMPANY.COMPANY_TRADED_ORDERS', '/company-traded-orders', {
      order_by: '-original_booking_date',
      'filters[settled]': false
    });

    // Preordenes
    this.addNavTab('COMPANY.PREORDERS', '/preorders');

    // if (this.company.hasModule('market')) {
    //   this.addNavTab('COMPANY.TRADED_ORDERS', '/traded-orders', { order_by: '-original_booking_date' });
    // }
  }

  private addSlotNavItem(): void {
    if (!this.company.hasModule('slots')) return;

    this.addNavTab('COMPANY.SLOTS', '/slots', {
      order_by: '-date',
      validity: 'on_date'
    }, undefined, true);
  }

  private addBartersNavItem(): void {
    if (!this.company.hasModule('barters')) return;

    this.addNavTab('COMPANY.BARTERS', '/barters', {
      status_barter_proposals: 1,
      order_by: '-updated_at'
    });
  }

  private addSignaturesNavItem(): void {
    const marketConfiguration = this.company.market.configuration;

    if (this.company.hasModule('signatures') && environment.modules.signatures &&
      (marketConfiguration.signatures && marketConfiguration.signatures.enabled)) {
      this.addNavTab('COMPANY.SIGNATURES', '/envelopes', {
        order_by: '-updated_at'
      });
    }
  }

  private addFintechNavItem(): void {
    if (!this.company.hasModule('fintech')) return;

    this.addNavTab('COMPANY.FINTECH', '/fintech', null, undefined, true);
  }

  private addStatisticsNavItem(): void {
    if (environment.data_studio && this.company.hasModule('statistics')) {
      this.addNavTab('COMPANY.STATISTICS', '/statistics', undefined, 'bar_chart', true);
    }
  }

  private addImportedDataNavItem(): void {
    const marketConfiguration = this.company.market.configuration;

    if (this.company.hasModule('contracts') && environment.modules.imported_data && marketConfiguration.imported_data.collections.length > 0) {
      // TODO: This validation is a HOT FIX, delete as soon as possible.
      if (this.company.id !== 450) { // ADAMA(450)
        this.addNavTab('GLOBAL.CONTRACTS', '/imported-data', null, 'gavel', true);
      }
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

class NavTab {
  icon?: string;
  label: string;
  router_link: string;
  query_params?: any;
  activeInSub: boolean;
  id: number;

  constructor(data: Partial<NavTab> = {}) {
    Object.assign(this, data);
  }
}
