import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, concatMap, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Company } from '../../../models/company.model';
import { DataDogLoggerService } from '../../../services/data-dog-logger.service';
import { AuthorizationToken, OAuthParams } from '../../models/oauth.model';
import { User } from '../../models/user.model';
import { LoginService } from '../../services/login.service';
import { OAuthService } from '../../services/oauth.service';
@Component({
  selector: 'ag-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.scss']
})
export class OAuthComponent implements OnInit, OnDestroy {
  private s3AssetsUrl = 'https://s3.us-west-2.amazonaws.com'
  private subscriptions: Subscription[] = [];

  public oAuthParamsData: Partial<OAuthParams>;
  public company: Company;
  public user: User;
  public processing = false;
  public redirecting = false;
  public logoBaseUrl: string = this.s3AssetsUrl + `/${environment.api.base.split('https://')[1]}`;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private oAuthService: OAuthService,
    private loginService: LoginService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.validateParams()
      .pipe(
        concatMap(() => this.checkUser()),
        concatMap(() => this.getCompanyDataByClientId()),
      )
      .subscribe({
        error: error => {
          this.dataDogLoggerService.warn(error.message, error.error);

          if (!this.oAuthParamsData) return this.router.navigate(['/']);

          if (!this.user) {
            const params = Object.keys(this.oAuthParamsData).map(key => [key, this.oAuthParamsData[key]]);
            const urlSearchParams = new URLSearchParams(params);

            this.router.navigateByUrl("/login?" + urlSearchParams.toString())
          }
        }
      });
  }

  private getCompanyDataByClientId(): Observable<Company> {
    return this.oAuthService.getCompany(this.oAuthParamsData.client_id).pipe(
      tap((company) => {
        if (company) {
          this.company = company;
        } else {
          throw new Error('Error obtaining company data');
        }
      })
    );
  }

  private checkUser(): Observable<User> {
    return this.loginService.getCurrentUser().pipe(
      tap((user) => {
        if (user) {
          this.user = user;
        } else {
          throw new Error('Error obtaining user data');
        }
      })
    );
  }

  private validateParams(): Observable<any> {
    return this.route.queryParams.pipe(
      tap((params) => {
        const { client_id, callback_uri } = params;

        if (!client_id?.length || !callback_uri?.length) {
          throw new Error('Client id and Callback uri are mandatory');
        } else {
          this.oAuthParamsData = params;
        }
      })
    );
  }

  public authorize(): void {
    this.processing = true;

    this.subscriptions.push(
      this.oAuthService.authorize(this.oAuthParamsData.client_id, this.user.id).subscribe(response => {
        if (response.authorization_token) {
          this.buildRedirect(response);
        }
      })
    );
  }

  private buildRedirect(credentials: AuthorizationToken): void {
    this.redirecting = true;

    setTimeout(() => {
      window.location.href = `${this.oAuthParamsData.callback_uri}?authorization_token=${credentials.authorization_token}`;
    }, 3000);
  }

  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
