import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[agAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  @Input() agAutoFocus: boolean;

  constructor(
    private element: ElementRef
  ) { }

  ngAfterViewInit(): void {
    if (this.agAutoFocus) setTimeout(() => {
      this.element.nativeElement.focus();
    });
  }
}
