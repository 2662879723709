import { Component } from '@angular/core';
import { MessageDropdownDirective } from '../../directives/message-dropdown.directive';

@Component({
  selector: '[messageToggle]',
  templateUrl: './message-toggle.component.html',
  styleUrls: ['./message-toggle.component.css']
})
export class MessageToggleComponent {

  constructor(
    public messageDropdown: MessageDropdownDirective
  ) { }
}
