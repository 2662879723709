import { Component, OnInit } from '@angular/core';
import { ComponentCommService } from '../../services/component-comm.service';
import { NavigationHistoryService } from '../../services/navigation-history.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  public history: string[];

  constructor(
    private componentComm: ComponentCommService,
    private navigationHistoryService: NavigationHistoryService
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: '404' });

    this.history = this.navigationHistoryService.get().reverse();
  }
}
