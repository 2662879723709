import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrderByPipe, PairsPipe } from 'ngx-pipes';

import { IntegrationsMonitorComponent } from '../company/components/integrations-monitor/integrations-monitor.component';
import { UiModule } from '../ui/ui.module';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { AuthorizationService } from './authorization.service';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    UiModule
  ],
  declarations: [
    AdminComponent,
    IntegrationsMonitorComponent
  ],
  providers: [
    AuthorizationService,
    OrderByPipe,
    PairsPipe
  ]
})
export class AdminModule { }
