import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';

import { ErrorService } from './services/error.service';

@Injectable()
export class DefaultErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector
  ) { }

  public handleError(error: Error): void {
    const zone: NgZone = this.injector.get(NgZone);
    const errorService: ErrorService = this.injector.get(ErrorService);

    if (zone && errorService) {
      zone.run(() => {
        errorService.handleError(error);
      });
    }
  }
}
