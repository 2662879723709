<nav *ngIf="menuStack?.length">
  <div #nav class="tablist no-select" role="tablist">
    <ul class="inner">
      <li *ngFor="let item of menuStack"
      class="tablist-tab"
      [class.active]="item.id === activeNavTab?.id"
      [routerLink]="item.router_link"
      [queryParams]="item.query_params">
        <i *ngIf="item.icon" class="material-symbols-rounded">{{ item.icon }}</i>
        {{ item.label|translate }}
      </li>
    </ul>
  </div>
</nav>