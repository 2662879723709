import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from './login.service';
import { User } from '../models/user.model';

@Injectable()
export class AdminGuard  {

  /** Current [[User]]. */
  public user: User;

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {
    this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.loginService.checkMailVerification().subscribe(session => {
      if (!session.user.admin) {
        this.router.navigate(['/login']);
      }
    });

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
