<div id="general-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <div class="jumbotron">
          <h1>{{ 'NOT_FOUND.TITLE'|translate }}
            <small><br>{{ 'NOT_FOUND.SUBTITLE'|translate }}</small>
          </h1>
          <p [innerHtml]="'NOT_FOUND.DESCRIPTION'|translate"></p>
          <div class="text-right"><a class="btn btn-agree btn-lg" 
            [routerLink]="['/']" role="button">{{ 'NOT_FOUND.BUTTON'|translate }}</a></div>
        </div>
        <!-- <ng-container *ngIf="history?.length > 1">
          <h5>{{ 'SIGNATURES.HISTORY'|translate }}</h5>
          <pre><ng-container *ngFor="let url of history; let i = index">#{{ (i + 1)|number: '2.0' }}: {{ url }}              
</ng-container></pre>
        </ng-container> -->
      </div>
    </div>
  </div>
</div>
