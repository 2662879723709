import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Account } from '../auth/models/account.model';
import { User } from '../auth/models/user.model';
import { LoginService } from '../auth/services/login.service';
import { Market } from '../company/modules/commercial/models/market.model';
import { Company } from '../models/company.model';
import { CompanyService } from '../services/company.service';
import { ComponentCommService } from '../services/component-comm.service';
import { DataDogLoggerService } from '../services/data-dog-logger.service';
import { SubscriptionManager } from '../utilities/subscription-manager';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent extends SubscriptionManager implements OnInit, OnDestroy {

  public accounts: Account[];
  public atLeastOneCompanyActive: boolean;
  public countDownValue: number = 0;
  public filterKey: string;
  public marketsPresent: Market[];
  public secondsToReSend: number = 0;
  /** Current [[User]]. */
  public user: User;
  public loading: boolean;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private componentComm: ComponentCommService,
    private companyService: CompanyService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private dataDogLoggerService: DataDogLoggerService
  ) {
    super();
    this.companyService.unset();
  }

  public set(company: Company): void {
    if (company.activation_level) {
      this.companyService.set(company);
      this.router.navigate(['/company', company.id, 'home']);
    }
  };

  ngOnInit(): void {
    this.loading = true;
    this.componentComm.emit({ name: 'app-title', title: 'GLOBAL.MY_COMPANIES' });

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      if (!user) return;

      this.user = user;

      this.checkTimeToResend();
      this.loadData();
    }));
  }

  private loadData() {
    if (!this.user.mail_verified) return;

    this.subscriptions.push(this.loginService.watchAccounts().subscribe(accounts => {
      if (!accounts) return;

      this.accounts = accounts;

      // Ignore super admin accounts (deprecated, filtered in backend)
      // this.accounts = accounts.filter(account => {
      //   return typeof account.role.id == 'number' && account.role.id < 10;
      // });

      this.accounts.forEach(account => {
        if (account.company.activation_level && account.company.activation_level.id) this.atLeastOneCompanyActive = true;

        const exists = this.marketsPresent?.find(m => m.id == account.company.market.id);
        if (!exists) this.marketsPresent = [...(this.marketsPresent || []), account.company.market];
      });

      const queryParams = this.route.snapshot.queryParams;
      if (this.accounts.length == 1 && queryParams.referral == 'login') {
        // It has only one company
        this.set(this.accounts[0].company);
      } else {
        this.loading = false;
        this.focus();
      }
    }));
  }

  public sendConfirmationEmail(): void {
    if (this.user.mail_verified) return;

    this.processing = true;
    this.secondsToReSend = 0;

    this.subscriptions.push(
      this.loginService.resendVerification(this.user.email).subscribe({
        next: res => {
          this.secondsToReSend = res.data.retryAfter;
          this.toastrService.success(this.translateService.instant('EMAIL.EMAIL_SEND_SUCCESS'));
          this.processing = false;
        },
        error: error => {
          this.secondsToReSend = error.data.retryAfter;
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      })
    );
  }

  /** If user's email si not verified, check the waiting time to resend verification. */
  private checkTimeToResend(): void {
    if (this.user.mail_verified || this.processing || this.secondsToReSend > 0) return;

    this.processing = true;

    this.subscriptions.push(
      this.loginService.getVerificationRemainingTimeToTry(this.user.email).subscribe({
        next: res => {
          this.secondsToReSend = res.data.retryAfter;
          this.processing = false;
        },
        error: error => {
          this.secondsToReSend = error.data.retryAfter;
          this.processing = false;
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      })
    );
  }

  private focus(delay: number = 0): void {
    setTimeout(() => {
      const input = document.getElementById('filterKey');
      if (input) input.focus();
    }, delay);
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    super.ngOnDestroy();
  }
}
