import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgPipesModule } from 'ngx-pipes';

import { UiModule } from '../ui/ui.module';
import { LandingComponent } from './landing/landing.component';

const routes: Routes = [
  {
    path: 'alimentos',
    component: LandingComponent,
    data: {
      marketId: 5,
      language: 'es'
    }
  },
  {
    path: 'food',
    component: LandingComponent,
    data: {
      marketId: 5,
      language: 'en'
    }
  },
  {
    path: 'ar',
    component: LandingComponent,
    data: {
      marketId: 2,
      language: 'es'
    }
  },
  {
    path: 'br',
    component: LandingComponent,
    data: {
      marketId: 4,
      language: 'pt'
    }
  },
  {
    path: 'py',
    component: LandingComponent,
    data: {
      marketId: 3,
      language: 'es'
    }
  }
];

@NgModule({
  declarations: [
    LandingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgPipesModule,
    RouterModule.forRoot(routes),
    UiModule
  ]
})
export class MarketModule { }
