<ng-template #messageModal>
  <div class="modal-header">
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title" [innerHTML]="notification.subject"></h4>
  </div>
  <div class="modal-body">
    <p class="text-right small text-muted text-capitalize">{{ notification.createdAt | date:"EEEE, d 'de' MMMM, y - HH:mm" }}</p>
    <div [innerHTML]="notification.message"></div>
  </div>
</ng-template>
