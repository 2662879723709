import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToInstance } from "class-transformer";
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, startWith } from 'rxjs/operators';

import { JSend } from '../models/jsend.model';
import { Notification } from '../models/notification.model';
import { PusherService } from './pusher.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationsUrl = '/:apiBase/user/:userId/inbox';
  private notificationssNotReadUrl = this.notificationsUrl + '/not-read';
  private notificationById = this.notificationsUrl + '/:inboxId';
  private markReadUrl = this.notificationById + '/markRead';

  constructor(
    private http: HttpClient,
    private pusherService: PusherService
  ) { }

  public get(userId: number, lastId?: string, limit: number = 25): Observable<{
    lastId?: string,
    notifications: Notification[]
  }> {
    const url = this.notificationsUrl
      .replace(":userId", userId + '');

    // Params Object
    let params: {
      limit: number,
      lastId?: string
    } = { limit };
    if (lastId) params = { ...params, lastId };

    return this.http.get<JSend<{
      inbox: Notification[]
    }>>(url, { params: params, observe: 'response' }).pipe(map(response => {
      return {
        lastId: response.headers.get('X-Last-Id'),
        notifications: plainToInstance(Notification, response.body.data.inbox)
      };
    }));
  }

  private getNotification(userId: number, inboxId: string): Observable<Notification> {
    const url = this.notificationById
      .replace(':userId', `${userId}`)
      .replace(':inboxId', `${inboxId}`);

    return this.http.get<Notification>(url).pipe(
      map(response => {
        return plainToInstance(Notification, response.data.inbox);
      })
    );
  }

  private getUnread(userId: number, limit: number = 100): Observable<Notification[]> {
    const url = this.notificationssNotReadUrl
      .replace(":userId", userId + '');

    // Params Object
    const params = { limit };

    return this.http.get<JSend<{
      inbox: Notification[]
    }>>(url, { params: params }).pipe(map(response => {
      return plainToInstance(Notification, response.data.inbox);
    }));
  }

  public watchUnread(userId: number): Observable<Notification[]> {
    return this.pusherService.listen('private_' + userId, 'notification').pipe(
      startWith({}),
      mergeMap(notification => {
        return this.getUnread(userId);
      })
    );
  }

  public watchNew(userId: number): Observable<Notification> {
    return this.pusherService.listen('private_' + userId, 'notification').pipe(
      filter(notification => {
        return !!notification.message_id;
      }),
      mergeMap(notification => {
        return this.getNotification(userId, notification.message_id);
      })
    );
  }

  public toggleRead(notification: Notification): Observable<{
    status: string
  }> {
    const url = this.markReadUrl
      .replace(":userId", notification.userId + '')
      .replace(":inboxId", notification.id + '');

    notification.read = !notification.read;

    return this.http.post<{
      status: string
    }>(url, { read: notification.read });
  }

  public setRead(notification: Notification): Observable<{
    status: string
  }> {
    if (!notification.read) {
      return this.toggleRead(notification);
    } else {
      return of({
        status: 'success'
      });
    }
  }

  public setAllRead(userId: number): Observable<any> {
    const url = this.notificationsUrl
      .replace(':userId', `${userId}`) + "/markRead";

    return this.http.post<any>(url, {});
  }
}
