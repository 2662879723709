import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GroupedMarkets, MarketType } from '../../company/modules/commercial/models/market-type.model';
import { ComponentCommService } from '../../services/component-comm.service';
import { MarketService } from '../../services/market.service';

@Component({
  selector: 'app-select-market',
  templateUrl: './select-market.component.html',
  styleUrls: ['./select-market.component.scss']
})
export class SelectMarketComponent implements OnInit {

  public marketsByType: GroupedMarkets[];
  /** Determines if the market type selector is displayed. */
  public grouped: boolean;
  public marketType: MarketType;
  public types: MarketType[];

  constructor(
    private router: Router,
    private componentComm: ComponentCommService,
    private marketService: MarketService
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'GLOBAL.ADD_COMPANY' });

    // no necesito desuscribirme porque es un stream de http
    this.marketService.getMarketsByType().subscribe(groups => {
      this.marketsByType = groups;

      this.types = [];

      groups.forEach(row => {
        const type = row.key;
        this.types.push(type);
      });

      if (this.types.length === 1) {
        // If there is only one market type, skip asking
        this.setType(this.types[0]);
      };
    });

  }

  public setType(market_type: MarketType): void {
    const group = this.marketsByType.filter(group => {
      return group.key.id === market_type.id;
    })[0];

    if (group.values.length === 1) {
      this.router.navigate(['register-company', 'market', group.values[0].id]);
    } else {
      this.marketType = market_type;
    }
  }
}
