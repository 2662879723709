<div class="container">
  <div class="content-box">
    <div class="row hidden-print" *ngIf="marketsByType && (marketsByType.length > 1 || marketsByType[0].values.length > 1)">
      <div class="col-md-12 product-selector">
        <p class="small"><b>{{ 'DOCUMENTS.SELECT_PRODUCT'|translate }}</b></p>
        <div class="market-selector">
          <div *ngFor="let type of marketsByType">
            <div class="small text-muted m-b1"
            *ngIf="marketsByType.length > 1">{{ type.key.name }}</div>
            <div class="btn-group" data-toggle="buttons">
              <button *ngFor="let market of type.values"
              type="button"
              class="btn btn-default btn-lg" [class.active]="market.slug === marketId" routerLink="/documents/{{ documentType }}/{{ market.slug }}">
                <img src="{{ market.logo }}" class="mkt-icon">
                {{ market.name }}
                <!-- <div><small class="text-muted hidden-xs">{{ market.description }}</small></div> -->
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row visible-print">
      <!-- Header for printed document -->
      <div class="col-xs-12 flex-center-distributed">
        <div class="logo" title="Agree">
          <img src="/assets/img/logo.png">
        </div>
        <ng-container *ngFor="let type of marketsByType">
          <ng-container *ngFor="let market of type.values">
            <h3 *ngIf="market.slug === marketId">{{ 'DOCUMENTS.SELECTED_MARKET'|translate }} {{ market.name }}</h3>
          </ng-container>
        </ng-container>
      </div>
      <div class="col-xs-12"><hr></div>
    </div>
    <div class="row" *ngIf="marketsByType">
      <div class="col-md-9 scrollable-pane legal-text">
        <label
        class="btn btn-link btn-sm pull-right"
        title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}"
        (click)="htmlClipboardService.copy('div#docContent')">
          <!-- <span class="material-symbols-rounded">content_copy</span> -->
          {{ 'GLOBAL.COPY_CLIPBOARD'|translate }}
        </label>
        <div id="docContent" assetInclude="/assets/html/{{ marketId }}-{{ documentType }}-{{ currentLang }}.html"></div>
      </div>
      <div class="col-md-3 hidden-print">
        <h5>{{ 'DOCUMENTS.RELATED_DOCS'|translate }}</h5>

        <button *ngFor="let document of documentsList | keyvalue" routerLink="/documents/{{ document.key }}/{{ marketId }}" [class.active]="document.key === documentType" class="btn btn-default btn-block" type="button">{{  document.value|translate }}</button>
        
        <a href="https://www.agree.ag/cookies/index.html" target="_blank" class="btn btn-default btn-block">Cookies</a>

        <button routerLink="/login" class="btn btn-agree btn-block" type="button" *ngIf="!user; else UserLogin">{{ 'DOCUMENTS.ENTER'|translate }}</button>
        <ng-template #UserLogin>
        <button routerLink="/companies" class="btn btn-agree btn-block" type="button">{{ 'GLOBAL.MY_COMPANIES'|translate }}</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
