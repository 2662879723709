<app-message-modal #messageModal></app-message-modal>

<ag-cellphone-verification #cellVerification></ag-cellphone-verification>

<ag-network-invite-modal #inviteModal
[company]="company"></ag-network-invite-modal>

<!-- Header -->
<nav class="navbar navbar-default navbar-static-top no-select" *ngIf="showHeader">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header">
      <a class="navbar-brand" routerLink="/">
        <div class="logo" title="{{ themeService.theme.title }}"></div>
      </a>
    </div>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="navbar-collapse" *ngIf="user">
      <ul class="nav navbar-nav navbar-right">
        <!-- button CREATE -->
        <li *ngIf="company && createButtonStack?.length">
          <div class="btn-group" dropdown>
            <button type="button"
            #createRoute="withRoute" withRoute="{{ createButtonStack[0].route }}" id="header-order-button"
            [routerLink]="createButtonStack[0].link"
            (click)="createButtonStack[0].click ? createButtonStack[0].click() : null"
            [title]="createButtonStack[0].title|translate"
            class="btn btn-agree" [disabled]="createRoute.active">
              <ng-container *ngIf="createButtonStack[0].iconHtml">
                <i class="material-symbols-rounded">{{ createButtonStack[0].iconHtml }}</i>&nbsp;
              </ng-container>
              {{ createButtonStack[0].title|translate }}
            </button>

            <!-- If auction is no enabled hide from here -->
            <button *ngIf="createButtonStack.length > 1" id="create-toggle" type="button"
            class="btn btn-agree dropdown-toggle dropdown-toggle-split"
            dropdownToggle>
              <span class="caret"></span>
              <!-- <span class="sr-only">Toggle Dropdown</span> -->
            </button>
            <ul *dropdownMenu class="dropdown-menu with-icons">
              <li *ngFor="let item of arrayOne(createButtonStack.length - 1); let i = index;" #createRoute="withRoute"
                withRoute="{{ createButtonStack[i+1].route }}" [class.disabled]="createRoute.active">
                <a [routerLink]="createButtonStack[i+1].link"
                (click)="createButtonStack[i+1].click ? createButtonStack[i+1].click() : null"
                [title]="createButtonStack[i+1].title|translate">
                  <i class="material-symbols-rounded" *ngIf="createButtonStack[i+1].iconHtml">{{ createButtonStack[i+1].iconHtml }}</i>
                  {{ createButtonStack[i+1].title|translate }}
                </a>
              </li>
            </ul>
          </div>
        </li>
        <!-- drop COMPANY -->
        <li id="header-company-menu" dropdown class="dropdown" [isDisabled]="!user.mail_verified"
        *ngIf="company && companyMenu">
          <a href id="company-menu" dropdownToggle (click)="false"
          aria-controls="company-menu-dropdown">
            <div class="company-logo" [style.backgroundImage]="company.logo|trustStyleUrl"></div>
            <span class="hidden-xs">
              {{ company.name }}
              <span class="caret" *ngIf="companyMenu.length"></span>
            </span>
          </a>
          <ng-container *ngIf="companyMenu.length">
            <ul id="company-menu-dropdown" *dropdownMenu class="dropdown-menu with-icons"
            role="menu" aria-labelledby="company-menu">
              <ng-container *ngFor="let section of companyMenu; let i = index;">
                <ng-container *ngTemplateOutlet="menuSection; context:{section: section, i: i}"></ng-container>
              </ng-container>
            </ul>
          </ng-container>
        </li>
        <!-- drop USER -->
        <li id="header-user-menu" dropdown class="dropdown" *ngIf="user && userMenu">
          <a href id="user-menu" dropdownToggle (click)="false"
          aria-controls="user-menu-dropdown">
            <i class="material-symbols-rounded">{{ user.admin ? 'manage_accounts' : 'person' }}</i>
            <span class="hidden-xs">
              {{ user.name }}
              {{ user.last_name }}
              <span class="caret" *ngIf="userMenu.length"></span>
            </span>
          </a>
          <ng-container *ngIf="userMenu.length">
            <ul id="user-menu-dropdown" *dropdownMenu class="dropdown-menu with-icons"
            role="menu" aria-labelledby="user-menu">
              <ng-container *ngFor="let section of userMenu; let i = index;">
                <ng-container *ngTemplateOutlet="menuSection; context:{section: section, i: i}"></ng-container>
              </ng-container>
            </ul>
          </ng-container>
        </li>
        <!-- Network request -->
        <li *ngIf="pendingRequests &&
        company?.hasModule('my-network')">
          <a routerLink="company/{{company.id}}/network"
          [queryParams]="{order_by: '-verification_date'}"
          title="{{ 'HEADER.PENDING_REQUESTS'|translate }}">
            <span class="animated infinite inbox-icon" [class.tada3rd]="pendingRequests > 0">
              <i class="material-symbols-rounded">apartment</i>
              <span class="badge badge-danger badge-sup" *ngIf="pendingRequests > 0">{{ pendingRequests > 99 ? '99+' : pendingRequests }}</span>
            </span>
          </a>
        </li>
        <!-- drop ALERTS -->
        <li *ngIf="user.mail_verified" id="header-alerts" messageDropdown [userId]="user.id" dropdown class="dropdown">
          <a messageToggle dropdownToggle (click)="false" title="{{ 'HEADER.NOTIFICATIONS'|translate }}"
            class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          </a>
          <ul messageMenu *dropdownMenu class="dropdown-menu" #messageMenu="messageMenu">
          </ul>
        </li>
      </ul>
    </div><!-- /.navbar-collapse -->
  </div><!-- /.container-fluid -->
</nav>

<ng-template #menuSection let-i="i" let-section="section">
  <li *ngIf="i > 0" role="separator" class="divider"></li>
  <li *ngIf="section.title" class="dropdown-header">{{ section.title|translate }}</li>
  <li *ngFor="let item of section.items">
    <a *ngIf="item.routerLink"
    [routerLink]="item.routerLink" [queryParams]="item.queryParams">
      <ng-container *ngTemplateOutlet="itemLabel; context:{item: item}"></ng-container>
    </a>
    <a *ngIf="item.click"
    (click)="item.click()">
      <ng-container *ngTemplateOutlet="itemLabel; context:{item: item}"></ng-container>
    </a>
  </li>
</ng-template>

<ng-template #itemLabel let-item="item">
  <i *ngIf="item.materialIcon" class="material-symbols-rounded {{ item.iconClass }}">{{ item.materialIcon }}</i>
  <i *ngIf="item.glyphicon" class="glyphicon {{ item.glyphicon }} {{ item.iconClass }}"></i>
  {{ item.title|translate }}
</ng-template>