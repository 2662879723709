import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ComponentCommService } from '../../../services/component-comm.service';
import { Integration } from '../../models/integration.model';
import { IntegrationsService } from '../../services/integrations.service';

@Component({
  selector: 'integrations-monitor',
  templateUrl: './integrations-monitor.component.html',
  styleUrls: ['./integrations-monitor.component.scss']
})
export class IntegrationsMonitorComponent implements OnInit, OnDestroy {

  public integrations: Integration[];
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private componentComm: ComponentCommService,
    private integrationsService: IntegrationsService
    // private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.componentComm.emit({ name: 'app-title', title: 'INTEGRATIONS.MONITOR' });

    this.loadIntegrations();
    // this.subscriptions.push(this.route.parent.params.subscribe(params => {
    // }));
  }

  private loadIntegrations(): void {
    this.processing = true;
    this.subscriptions.push(this.integrationsService.get().subscribe({
      next: integrations => {
        this.integrations = integrations;
        this.processing = false;
      },
      error: () => {
        this.processing = false;
      }
    })
    );
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
